import Sortable from 'sortablejs';

$(document).ready(function(){

  if ( $( "#orderlist" ).length ) {

    Sortable.create(orderlist, {
      onUpdate: function (evt/**Event*/){
        var items = [];
        $('.orderitem').each(function(i, elem) {
          items.push($(elem).data("id"));
        });
        var order = {"order": items}
        $("#sortable_order").val(JSON.stringify(order));
        $(".orderupdate").addClass("btn-warning");
      }
    });
  }

  $('.orderupdateform').on('ajax:success', function(event, data, status, xhr) {
    $(".orderupdate").removeClass("btn-warning");
    $('#save-order-text').html("Volgorde opgeslagen");
  });

});
