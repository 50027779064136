import dt from 'datatables.net-bs';
import 'datatables.net-bs/css/dataTables.bootstrap.css';

$(document).ready(function() {

  $('.simple-datatable').DataTable({
    "bPaginate": false,
    "info": false,
  });


  $('.datatable').DataTable({
    "bPaginate": false,
    "info": false,
    "columns": [
          {},
          {},
          {"orderable": false},
          {"searchable": false, "orderable": false },
    ]
  });

  $('.pagedatatable').DataTable({
    "bPaginate": false,
    "info": false,
    "columns": [
          {},
          {},
          {},
          {"orderable": false},
          {"searchable": false, "orderable": false },
    ]
  });


  $('.user-datatable').DataTable({
    "bPaginate": false,
    "info": false,
    "columns": [
          {},
          {},
          {"searchable": false},
    ]
  });

  $('.result-datatable').DataTable({
    "bPaginate": false,
    "info": false,
  });

  $('.show-datatable').on('click', function() {
    $('.result-datatable').toggle();
    $('.hide-datatable').toggle();
    $('.show-datatable').toggle();
  });

  $('.hide-datatable').on('click', function() {
    $('.result-datatable').toggle();
    $('.hide-datatable').toggle();
    $('.show-datatable').toggle();
  });


});
