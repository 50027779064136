$(document).ready(function(){
  $('#admin_page_title, #admin_page_template').change(function() {
    generatePageSlug();
  });

  function generatePageSlug(){
    var title = slugify($('#admin_page_title').val());
    var template = slugify($('#admin_page_template option:selected').text());
    $('#admin_page_slug').val(template + "/" + title);
  }

  function slugify(oldstr) {
    var replace = '-';
    var str = oldstr.toString().replace(/[\s\.]+/g,replace).toLowerCase().replace(new RegExp('[^a-z0-9'+replace+']','g'),replace).replace(new RegExp(replace+'+','g'),replace);
    if ( str.charAt(str.length-1) == replace ) str = str.substring(0,str.length-1);
    return str;
  }
});
