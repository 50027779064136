$(document).ready(function(){
  $('[data-toggle="popover"]').popover();

  $('.display-admin-options').click(function() {
    $('.adminoptions').show();
  });

  $('img').addClass("img-responsive");

  $(".scroltop_link").click(function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });

  $(".back").click(function () {
    history.back();
  });

  $('.accept-cookie').on('ajax:success', function(event, data, status, xhr) {
    $('#cookie').remove();
  });

  $('#email_user_selector').change(function() {

    var option = $('#email_user_selector option:selected');

    var name = option.data("name");
    var email = option.data("email");
    $('#admin_email_email').val(email);
    $('#admin_email_name').val(name);
  });


  //*** folder management

  update_multiselect();

  $('#question_multiple_select_col0open').click(function() {
    update_multiselect();
  });

  function update_multiselect() {
    var open = $('#question_multiple_select_col0open').is(':checked');
    $('#question_multiple_select_question_count').prop('disabled', !open);
    $('#question_multiple_select_option_title').prop('disabled', open);

  }
});
