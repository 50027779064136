import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';

import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/textcolor';

import 'tinymce-i18n/langs/nl';
import 'tinymce/skins/lightgray/content.min.css';
import 'tinymce/skins/lightgray/skin.min.css';

function tinyMceBig() {
    tinymce.init({
      selector: 'textarea.bigtinymce',
      plugins: ["link media image code fullscreen table lists textcolor"],
      toolbar: "undo redo | styleselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | media | code | fullscreen",
      language: "nl",
      image_list: "/admin/pictures.json",
      link_list: "/admin/menus/links.json",
      relative_urls: false,
      height: 500,
      branding: false,
      skin: false,
    });
}

function tinyMce() {
    tinymce.init({
      selector: 'textarea.tinymce',
      plugins: ["link media image code fullscreen table lists textcolor"],
      toolbar: "undo redo | styleselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | media | code | fullscreen",
      language: "nl",
      image_list: "/admin/pictures.json",
      link_list: "/admin/menus/links.json",
      relative_urls: false,
      height: 170,
      branding: false,
      skin: false,
      style_formats_merge: true,
      style_formats: [
      // Your format as described on this page
      {
        title: 'Werkmap resultaat blok',
        selector: 'div',
        classes: 'resultblock'
      }
    ]
    });
}

tinyMceBig();
tinyMce();
