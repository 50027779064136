class MOV_tools {

  constructor() {
  }

  changesNotSavedYet(qid) {
    this.setSaveButtonText(qid, "Laatste aanpassingen zijn nog niet opgeslagen.");
    $(".save-"+ qid).removeClass("btn-default");
    $(".save-"+ qid).addClass("btn-warning");
  }
  setSaveButtonText(qid, text) {
    $('#save-button-text' + qid).html(text);
  }
}

export default MOV_tools;
