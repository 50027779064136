import MOV_tools from './tools.js';

class mov_Point {

  constructor(init) {
    this.qid;
    this.max;
    this.bid;
    this.init = init;
    this.total2 = new Array();
    this.tools = new MOV_tools();
  }

  updateOption(qid) {
    this.qid = qid;
    var rootwindow = $(".qwindow" + qid);
    this.bid = rootwindow.data("bid");
    this.max = parseInt(rootwindow.data("maxpoints"));
    this.generatePointAnswer();
    this.calcResult();

    if (this.init == false) {
      rootwindow.removeClass("saved");
      this.tools.changesNotSavedYet(qid);
    }
  }

  generatePointAnswer() {
    var total = 0;
    var self = this;
    var map = 0;

    var options = [];
    var index = 1;
    $(".bqwindow" + self.bid + "-" + self.qid + " .qpoint-option").each(function() {
      var answer = {};
      answer.qid = $(this).data("id");
      answer.map = $(this).data("map");
      answer.val = $(this).val();
      $(".point-result-" + self.qid + "-" + answer.map).html(answer.val);
      total += parseInt(answer.val);
      map = parseInt(answer.map);
      options.push(answer);
      index++;
    });
    $(".qpoint-total-" + self.qid).val(total);
    if (total > self.max)
      $(".qpoint-total-" + self.qid).addClass("exceed-max");
    else
      $(".qpoint-total-" + self.qid).removeClass("exceed-max");

    var answer = {"options": options}
    $("#answer" + self.qid).val(JSON.stringify(answer));

    $(".point2-" + map).html(total);
  }

  updateBonus(qid) {
    this.qid = qid;
    var rootwindow = $(".qwindow" + qid );
    this.bid = rootwindow.data("bid");

    if (this.init == false) {
      rootwindow.removeClass("saved");
      this.tools.changesNotSavedYet(qid);
    }

    this.generateBonusAnswer();

    $(".point-result").removeClass("bonus");

    $(".qpointextra-option").each(function() {
      var option_id = $(this).val();
      var option = $(".qpoint-option-" + option_id);
      var option_qid = option.data("qid");
      var option_map = option.data("map");
      $(".point-result-" + option_qid + "-" + option_map).addClass("bonus");
    });

    this.calcResult();
  }

  generateBonusAnswer() {
    var self = this;
    var options = [];
    $(".qpointextra-option").each(function() {
      var answer = {};
      answer.qid = $(this).data("id");
      answer.val = $(this).val();
      options.push(answer);
    });
    var answer = {"options": options}
    $("#answer" + self.qid).val(JSON.stringify(answer));
  }

  calcResult() {
    var points = parseInt($(".qtype16").data("extrapoints"));

    // calculate column totals type 1
    var totals = [];
    $(".bid" + this.bid + " .point-result").each(function() {
      var col = parseInt($(this).data("col"));
      var val = parseInt($(this).html());

      var bonus = 0;
      if ($(this).hasClass("bonus")) {
        bonus = points;
      }

      if (totals[col])
        totals[col] += val + bonus;
      else
        totals[col] = val + bonus;
    });

    for(var i = 1; i <= totals.length; i++) {
      $(".point-result-total-" + this.bid + "-" + i).html(totals[i]);
    }

  }

}

export default mov_Point;
