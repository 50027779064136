import 'password-strength-meter/dist/password.min.js';
import 'password-strength-meter/dist/password.min.css';

$(document).ready(function() {
  $('.pwdtester').password({
    shortPass: 'Wachtwoord is te kort',
    badPass: 'Zwak wachtwoord; probeer een combinatie van letters en nummers',
    goodPass: 'Normaal wachtwoord',
    strongPass: 'Sterk wachtwoord',
    enterPass: '',
    showPercent: false,
    showText: true, // shows the text tips
    animate: true, // whether or not to animate the progress bar on input blur/focus
    animateSpeed: 'fast', // the above animation speed
    username: false, // select the username field (selector or jQuery instance) for better password checks
    usernamePartialMatch: true, // whether to check for username partials
    minimumLength: 8 // minimum password length (below this threshold, the score is 0)
  });
});
