import mov_Point from './point.js';
import mov_SelectionList from './selectionlist.js';

$(document).ready(function() {

	$("#toggle-feedback").click(function(){
		$("#feedback-form-holder").toggle();

  	var pos = $('#feedback-form-holder').position();
  	$("html, body").animate({
			scrollTop: pos.top + 500
      	}, 500);
	});

	$("#toggle-feedback-end").click(function(){
		if (check_errors()) {
			$("#feedback-form-holder").toggle();
	  	var pos = $('#feedback-form-holder').position();
	  	$("html, body").animate({
				scrollTop: pos.top + 500
	      	}, 500);
  	}
	});


	$(".select-list-option").change(function(){
		var sl = new mov_SelectionList($(this).data("qid"));
		sl.updateOptions();
		$(".qtype7").trigger("updated");
	});

	$(".selectoptionstop, .selectoptionstop_reasons").change(function(){
		var sl = new mov_SelectionList($(this).data("qid"));
		sl.updateSelectonTopOptions(true);
		$(".qtype7").trigger("updated");
	});

	$(".qtype7").on("updated", function(){
		var sl = new mov_SelectionList($(this).data("qid"));
		console.log("trigers");
		sl.updateResults();
	});


	$(".qopengroups-option").change(function(){
		var qid = $(this).data("qid")
		var rootwindow = $(".qwindow" + qid);

		var options = [];
		$(".qwindow" + qid + " .qopengroups-option").each(function() {
			var answer = {};
			answer.qid = $(this).data("id");
			answer.val = $(this).val();
			options.push(answer);
		});
		var answer = {"options": options}
		$("#answer" + qid).val(JSON.stringify(answer));
		rootwindow.removeClass("saved");
		changesNotSavedYet(qid);
	});


	$(".qpoint-option").each(function() {
			var point = new mov_Point(true);
			point.updateOption($(this).data("qid"));
	});

	$(".qpoint-option").change(function() {
			var point = new mov_Point(false);
			point.updateOption($(this).data("qid"));
	});

	$(".qpointextra-option").each(function() {
			var point = new mov_Point(true);
			point.updateBonus($(this).data("qid"));
	});

	$(".qpointextra-option").change(function() {
			var point = new mov_Point(false);
			point.updateBonus($(this).data("qid"));
	});

	$(".pulldown").change(function(){
		var qid = $(this).data("qid");
		var rootwindow = $(".qwindow" + qid );
		rootwindow.removeClass("saved");
		changesNotSavedYet(qid);
	});


	// toggle save warning when changing textareas
	$(".questionwindow textarea").change(function(){
		var rootwindow = $(this).parent().parent().parent();
		var qid = rootwindow.data("qid");
		rootwindow.removeClass("saved");
		changesNotSavedYet(qid);
	});


	$(".questionwindow .short-input-textarea").change(function(){
		var rootwindow = $(this).parent().parent().parent();
		var qid = rootwindow.data("qid");
		rootwindow.removeClass("saved");
		changesNotSavedYet(qid);
	});

	$(".quality").change(function(){
		var qid = $(this).data("qid");
		var rootwindow = $(".qwindow" + qid );
		var questions = parseInt(rootwindow.data("maxanswers"));
		var options = [];

		for(var i = 1; i <= questions; i++) {
   		var answer = {};
   		answer.row = i;
			answer.q1 = $(".quality-" + qid + "-" + i + "-1").val();
			answer.q2 = $(".quality-" + qid + "-" + i + "-2").val();
			answer.q3 = $(".quality-" + qid + "-" + i + "-3").val();
			answer.q4 = $(".quality-" + qid + "-" + i + "-4").val();
			options.push(answer);
		}

  	var answer = {"options": options}
   	$("#answer" + qid).val(JSON.stringify(answer));


		rootwindow.removeClass("saved");
		changesNotSavedYet(qid);
	});

	$(".ms").change(function(){
		var qid = $(this).data("qid");
		var rootwindow = $(".qwindow" + qid );
		var questions = parseInt(rootwindow.data("mscount"));
		var options = [];

		for(var i = 1; i <= questions; i++) {
      		var answer = {};
      		answer.row = i;
      		answer.col0 = $(".ms-" + qid + "-0-" + i).val();
      		answer.col1 = $(".ms-" + qid + "-1-" + i).val();
      		answer.col2 = $(".ms-" + qid + "-2-" + i).val();
      		options.push(answer);
		}

  	var answer = {"options": options}
   	$("#answer" + qid).val(JSON.stringify(answer));

		rootwindow.removeClass("saved");
		changesNotSavedYet(qid);
		update_mc_result();
	});

	function update_mc_result() {
		// check if result is there

		// obtain window information from data tag and load qwindow
		var questionwindow_t19 = $(".qtype19");
		var target_id = questionwindow_t19.data("target");
		var questionwindow = $(".qwindow" + target_id);

		var questions = parseInt(questionwindow.data("mscount"));
		var qid = questionwindow.data("qid");

		// clear results
		$(".mc").html("");
		$(".mot-com-total").html("");
		$(".mc").data("count", 0);
		$(".mot-com-total").data("count", 0);

		for(var i = 1; i <= questions; i++) {
			var ans = $(".ms-" + qid + "-0-" + i).val();
			var mot = $(".ms-" + qid + "-1-" + i).val();
			var com = $(".ms-" + qid + "-2-" + i).val();
			var current = $(".mc-" +  mot + "-" + com).html();
			var current_count = $(".mc-" +  mot + "-" + com).data("count");
			var space = " ";
			if (current_count > 0)
				space = ", ";

			$(".mc-" +  mot + "-" + com).html(current + space + ans);
			$(".mc-" +  mot + "-" + com).data("count", current_count + 1);
		}

		combine_results("a");
		combine_results("b");
		combine_results("c");
		combine_results("d");

		analyse_results();
	}

	function combine_results(target) {
		$("table.mot-com-result td.result-" + target).each(function() {
			var current = $(".total-" + target).html();
			var current_count = $(".total-" + target).data("count");

			var space = ""
			if (current.length > 0)
				space = ", "

			if ($(this).html().length > 0 )
				$(".total-" + target).html(current + space + $(this).html());

			$(".total-" + target).data("count", current_count + $(this).data("count"));
		});
	}

	function analyse_results() {
		// clear results
		$(".mc-topresult1").html("");
		$(".mc-topresult2").html("");

		$(".mc-result-text").hide();


		var tota = $(".total-a").data("count");
		var totb = $(".total-b").data("count");
		var totc = $(".total-c").data("count");
		var totd = $(".total-d").data("count");

		var a = {};
		a.tot = tota;
		a.na = "A";
		a.weight = 4;

		var b = {};
		b.tot = totb;
		b.na = "B";
		b.weight = 2;

		var c = {};
		c.tot = totc;
		c.na = "C";
		c.weight = 3;

		var d = {};
		d.tot = totd;
		d.na = "D";
		d.weight = 1;

		var options = [];
		options.push(a);
		options.push(b);
		options.push(c);
		options.push(d);

		//Bij een gelijk aantal taken in een kwadrant gaat A voor C, C voor B en B voor D.

		// sort options according to points allocated to them.
		var res = options.sort(function(a, b) {
			// if total points are equal, order on weight
			if (a.tot == b.tot)
				return b.weight - a.weight;

			return b.tot - a.tot;
		});

 		$(".mc-topresult1").html(res[0].na);
 		$(".mc-result-text-" + res[0].na).show();
 		$(".mc-topresult2").html(res[1].na);
 		$(".mc-result-text-" + res[0].na + res[1].na).show();
	}

	function mc_order(){

	}

	update_mc_result();

	$(".questionwindow .inlineinput").change(function(){
		var rootwindow = $(this).parent().parent();
		var qid = rootwindow.data("qid");
		rootwindow.removeClass("saved");
		changesNotSavedYet(qid);
	});

	$('#account_settings').on('ajax:error', function(event, xhr, status, error) {
  		//$(this).append(xhr.responseText)
	});

	$('.new_answer, .edit_answer, .inline_answer').on('ajax:success', function(event) {
		var data = event.detail[0];
		$(".qwindow" + data.question_id).addClass("saved");
		removeErrorText(data.question_id);
		setSaveButtonText(data.question_id, "Succesvol opgeslagen.");
		$(".save-"+ data.question_id).removeClass("btn-warning");
		$(".save-"+ data.question_id).addClass("btn-success");

	});

	// check if all the required fields have been filled in
	$('.next-block-button').click(function() {
		check_errors();
	});

	function check_errors() {
		// clear up previous errors
		removeAllErrorTexts();

		var errors = 0;
		errors += check_required_answers();
		errors += check_unsaved_answers();

		if (errors > 0) {
			//locate the first error
          	var pos = $('.active_error').first().parent().parent().position();

            if (typeof pos === 'undefined'){
                //something went wrong
                console.log("We have errors but no target to scroll to. This should not happen.")
            }
            else {
                $("html, body").animate({
                    scrollTop: pos.top
                  	}, 500);
            }

			event.preventDefault();
			return false
		}
		return true
	}

	function check_required_answers() {
		var errors = 0;
		$('.required').each(function(i, elem) {
			var qid = $(elem).data("qid");
			var answer = $('#answer' + qid).val();
			if (answer === "" || answer === '{"options":[]}'){
				setErrorText(qid, "Deze vraag is verplicht.");
				errors += 1;
			}
			else {
				console.log(answer);
			}
		});

		return errors;
	}

	function check_unsaved_answers() {
		var errors = 0;
		$('.questionwindow').each(function(i, elem) {
			if (!$(elem).hasClass("saved")) {
				var qid = $(elem).data("qid");
				setErrorText(qid, "Het antwoord is nog niet opgeslagen");
				errors += 1;
			}
		});
		return errors;
	}

	// prevent closing a window when there are unsaved answers
    $(window).bind('beforeunload', function() {
		// clear up previous errors
		removeAllErrorTexts();

		// force a blur event so the changes are picked up
    	var focused = $(':focus');
    	focused.blur();

    	var errors = check_unsaved_answers();
        if (errors > 0)
            return 'Er zijn antwoorden die nog niet zijn opgeslagen. Wilt u de pagina echt verlaten?';
    });

	// check all selection lists and update the listers (selectionlist tops)
	$(".qtype5").each(function() {
		var sl = new mov_SelectionList($(this).data("qid"));
		sl.initOptions(false);
	});

	// init qtype7 (selection list tops results)
	$(".qtype7").trigger("updated");

	$(".selectoptionstop").each(function() {
		var sl = new mov_SelectionList($(this).data("qid"));
		sl.updateSelectonTopOptions(false);
	});

	if ( $( "#printpage" ).length ) {
		window.print();
	}

});

function changesNotSavedYet(qid) {
	setSaveButtonText(qid, "Laatste aanpassingen zijn nog niet opgeslagen.");
	$(".save-"+ qid).removeClass("btn-default");
	$(".save-"+ qid).addClass("btn-warning");
}

function setSaveButtonText(qid, text) {
	$('#save-button-text' + qid).html(text);
}

function setErrorText(qid, text) {
	var oldtext = $('#error-text' + qid).html();
	$('#error-text' + qid).html(oldtext + " " + text);
	$('#error-text' + qid).addClass("active_error");
}

function removeAllErrorTexts() {
	$('.error-text').each(function(i, elem) {
		$(elem).html("");
		$(elem).removeClass("active_error");
	});
}

function removeErrorText(qid) {
	$('#error-text' + qid).html("");
}
