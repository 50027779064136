import MOV_tools from './tools.js';

class mov_SelectionList {

	constructor(qid) {
		this.qid = qid;
		this.rootwindow = $(".qwindow" + qid);
		this.opts = [];
		this.tools = new MOV_tools();
	}

	initOptions(notify_changes) {
		var maxanswers = this.rootwindow.data("maxanswers");
		var named_options = [];
		var self = this;
		$(".qwindow" + self.qid + " .select-list-option:checked").each(function() {
			self.opts.push($(this).data("id"));
			var meta = {}
			meta.id = $(this).data("id");
			meta.title = $(this).data("qtitle");
			named_options.push(meta);
		});

		// if we reached the max allowed disable others
		if (this.opts.length >= maxanswers)
			$(".qwindow" + this.qid + " .select-list-option:not(:checked)").prop('disabled', true);
		else
			$(".qwindow" + this.qid + " .select-list-option:not(:checked)").prop('disabled', false);

		$('div[data-listen-id="' + this.qid + '"]').each(function() {
			self.set_selecton_top_options($(this).data("qid"), named_options, notify_changes);
		});
	}

	updateOptions() {
		this.initOptions(true);
		var answer = {"options": this.opts}
		$("#answer" + this.qid).val(JSON.stringify(answer));
		this.rootwindow.removeClass("saved");
		this.tools.changesNotSavedYet(this.qid);
	}

	set_selecton_top_options(qid, options, notify_changes) {
		// remove all the disabled attr on the current top selection options in this question
		$('.selectoptions_' + qid + ' option').removeAttr("disabled");
		console.log(qid);

		var output = [];
		// add empty option
		output.push('<option value=""> </option>');

		// add options from list
		$.each(options, function(key, value) {
			output.push('<option value="'+ value.id +'">'+ value.title +'</option>');
		});

		// push the options to the listeners
		$('.selectoptions_' + qid).each(function() {
			var selected = $(this).val();
			$(this).html(output.join(''));
			$(this).val(selected);
		});

		if (notify_changes)
			$(".selectoptions_" + qid).trigger("change");
	}

	updateSelectonTopOptions(notify_changes) {
		var options = [];
		var self = this;

		// remove all the disabled attr on the current top selection options in this question
		$('.selectoptions_' + self.qid + ' option').removeAttr("disabled");


		$('.selectoptions_' + self.qid).each(function() {
			var option = {}
			option.id = $(this).data("id")
			option.val = $(this).val();
			option.text = $('.selectoptions_' + option.id + '_' + self.qid + ' option:selected').text();
			option.reason = $('.selection_reason_' + option.id + '_' + self.qid).val();
			options.push(option);

			// if the current selected option has a value, disable this option in all the other selections within this question
			if(option.val != null) {
				if (option.val.length > 0)
					$('.selectoptions_' + self.qid + ' option[value="' + option.val + '"]').attr("disabled", "disabled");
			}
		});
		var answer = {"options": options}
		$("#answer" + this.qid).val(JSON.stringify(answer));
		if (notify_changes) {
			this.rootwindow.removeClass("saved");
			this.tools.changesNotSavedYet(this.qid);
		}
	}

	updateResults() {

		$(".qtype6").each(function() {
			var qid = $(this).data("qid");
			var max = $(this).data("max-answers");
			for(var i = 0; i < max; i++) {
				var select = $('.selectoptions_' + i + '_' + qid + ' option:selected');
				select.each(function() {
					$('.list_top_result_' + qid + '_' + i).html($(this).text());
				});
			}
		});
	}
}

export default mov_SelectionList;
