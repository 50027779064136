import 'bootstrap-datetime-picker/js/bootstrap-datetimepicker.js'
import 'bootstrap-datetime-picker/js/locales/bootstrap-datetimepicker.nl.js';

import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.css';


$(document).ready(function() {

  $('.datepicker').datetimepicker({
    autoclose: 'true',
    format: 'yyyy-mm-dd',
    minView: '2'
  });


  $('.mdatetimepicker').datetimepicker({
    autoclose: 'true',
    format: 'yyyy-mm-dd hh:ii',
    //minView: '2'
  }).on('changeDate', function(ev){
    console.log("called");
    $('.mdatetimepicker_end').datetimepicker('setStartDate', ev.date);
  });

  $('.mdatetimepicker_end').datetimepicker({
    autoclose: 'true',
    format: 'yyyy-mm-dd hh:ii',
  });

});
