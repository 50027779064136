$(document).ready(function(){

    // 0 = page
    // 5 = questions
    function set_menu_slug_type() {
      var selected = $('#admin_menu_menu_type').val();

      $('.mtype').each(function(i, elem) {
        if ($(elem).data("mtype") == selected) {
          $(elem).attr("name", "admin_menu[slug]");
          $(elem).attr("id", "admin_menu_slug");
          $(elem).parent().show();
        }
        else{
          $(elem).parent().hide();
          $(elem).attr("name", "temp" + $(elem).data("mtype"));
          $(elem).attr("id", "temp" + $(elem).data("mtype"));
        }
      });
    }

    $('#admin_menu_menu_type').change(function() {
      set_menu_slug_type();
    });

    set_menu_slug_type();
});
