import "slick-carousel";
import "slick-carousel/slick/slick.css";
// import 'slick-carousel/slick/slick-theme.scss';

$(document).ready(function () {
  $("#agendaitems").slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    prevArrow:
      '<div class="prev"><svg version="1.1" viewBox="0 0 24 24" preserveAspectRatio="xMinYMin meet" class="mTSButtonIcon"><g><line stroke-width="1" x1="" y1="" x2="" y2="" stroke="#449FDB" opacity=""></line></g><path d="M20.902 17.279c0.325 0.322 0.851 0.322 1.175 0 0.325-0.322 0.325-0.841 0-1.163l-9.49-9.396c-0.324-0.322-0.85-0.322-1.174 0l-9.49 9.396c-0.324 0.322-0.325 0.841 0 1.163s0.85 0.322 1.175 0l8.902-8.569 8.902 8.569z"></path></svg></div',
    nextArrow:
      '<div class="next"><svg version="1.1" viewBox="0 0 24 24" preserveAspectRatio="xMinYMin meet" class="mTSButtonIcon"><g><line stroke-width="1" x1="" y1="" x2="" y2="" stroke="#449FDB" opacity=""></line></g><path d="M3.098 6.721c-0.325-0.322-0.851-0.322-1.175 0-0.324 0.32-0.324 0.841 0 1.163l9.49 9.396c0.325 0.322 0.85 0.322 1.175 0l9.49-9.396c0.324-0.322 0.325-0.841 0-1.163s-0.852-0.322-1.175-0.001l-8.903 8.569-8.902-8.568z"></path></svg></div>',
  });
});
